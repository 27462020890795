@import "~react-image-gallery/styles/css/image-gallery.css";

.lykkehausoverlay {
  width: 80%;
  height: auto;
  position: absolute;
  z-index: 10;
  top: 95%;
  text-align: center;
  left: 10%;
  right: 10%;
}

@media (min-width: 1919px) {
  .lykkehausoverlay {
    top: 65%;
  }
}

.z-index-1 {
  z-index: 20;
}

.logo {
  width: 6em;
  height: auto;
  position: absolute;
  z-index: 1000;
  margin-top: -1.4em;
}

.navbar {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
}

@media (max-width: 1024px) {
  #basic-navbar-nav {
    padding-top: 6em;
    text-align: center;
}
}


@media (min-width: 992px) {
  .vertical-align{
    padding: 60% 0;
  }
  .vertical-align-2{
    padding: 50% 0;
  }
}

@media (min-width: 1200px) {
  .vertical-align{
    padding: 40% 0;
  }
  .vertical-align-2{
    padding: 40% 0;
  }
}


.indent {
  margin-left: 1em;
}


/* Bootstrap Ammendments */

.card-title {
  text-decoration: underline;
  text-decoration-color: #ffb97d;
}

.card {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

/* Footer Code */

.footer {
  background-color: #e6b4bf;
}

#copyright {
  font-size: 1em;
  font-weight: 400;
  color:#231f20;
}

#copyright a{
  font-size: 1em;
  font-weight: 400;
  color:#231f20;
}

/*Cookie Law Box */
.react-cookie-law-dialog {
  bottom: 0px;
}

/*The Blog*/
.blog-excerpt {
  color: #231f20 !important;
}

.blog-feature-image {
  width: 100%;
  height: auto;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.blog-post img {
  max-width: 20em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: 100%;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.blog-button {
  margin-top: 2em;
  margin-bottom: 2em;
}

.blog-page-title {
  text-decoration: underline;
}

/*Homepage*/

.parallax-home-200 {
  background-image: url("/pub-images/margate-large.jpg");
  height: 200px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
  position: relative
}
.parallax-home-700 {
  background-image: url("/pub-images/margate-large.jpg");
  height: 700px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
  position: relative
}

@media  (min-width: 1919px) {
.parallax-home-700 {
  background-image: url("/pub-images/margate-large.jpg");
  height: 900px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
}
}


.parallax-home-700-teal {
  background-color: #231f20E6;
  height: 260px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
}

@media (max-width: 1024px) {
.parallax-home-700-teal {
  background-color: #231f20;
  height: 300px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
}
}

.parallax-home-1000-teal {
  background-color: #006d76;
  height: 500px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
}

.accent-text {
  color: #E6B4BF !important;
}

@media (max-width: 1152px) {
.parallax-home-1000-teal {
  background-image: none;
  background-color: #006d76;
  height: 1000px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
}
.parallax-home-700 {
  background-image: url("/pub-images/margate-large.jpg");
  height: 900px;
  width: 100%;
  background-attachment: initial !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow: hidden !important;
  position: relative !important;
}
.parallax-home-200 {
  background-image: url("/pub-images/margate-large.jpg");
  height: 200px;
  width: 100%;
  background-attachment: initial !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow: hidden !important;
  position: relative !important;
}
}

@media (max-width: 450px) {
.parallax-home-1000-teal {
  background-image: none;
  background-color: #006d76;
  height: 1600px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
}
.parallax-home-700 {
  background-image: url("/pub-images/margate-large.jpg");
  height: 800px;
  width: 100%;
  background-attachment: initial !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow: hidden !important;
  position: relative !important;
}
.parallax-home-700-teal {
  background-color: #231f20;
  height: 700px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
}
.parallax-home-200 {
  background-image: url("/pub-images/margate-large.jpg");
  height: 200px;
  width: 100%;
  background-attachment: initial !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow: hidden !important;
  position: relative !important;
}
}


@media (min-width: 1919px) {
.parallax-home-1000-teal {
  background-color: #006d76;
  height: 400px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
}
}


.parallax-home-900 {
  background-image: url("/pub-images/margate-large.jpg");
  height: 900px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
}


.home-title-top {
  max-width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(230, 179, 190, 0.8);
  font-weight: 400;
}

.home-title-teal {
  max-width: 90%;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 10px;
  font-weight: 400;
}


.home-title-teal-left {
  max-width: 90%;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 10px;
  font-weight: 400;
}

.top-row {
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.top-text {
  font-size: 1.3rem !important
}

.title-area-text p {
  margin-bottom: 0.5em;
  color: #000000;
}

.white-text {
  color: #ffffff !important;
}

.feature-title h5{
margin-top: 1em;
color: #5C484C !important;
}

.title-area-text {
  font-size: 1.2rem;
  margin-top: auto;
  margin-bottom: auto;
}


#second-home-block {
    background-image: url("/pub-images/Artillery_road_03.jpg");
}

#second-home-block h1 {
    text-decoration: underline;
}

.home-title-text{
  color: #231f20;
  font-size: 1.1em ;
  margin-top: 2em;
}


#blog-header {
  background-image: url("/pub-images/Artillery_road_25.jpg");
}

#about-page-header {
  background-image: url("/pub-images/Artillery_road_29.jpg");
}

#service-page-header {
  background-image: url("/pub-images/Artillery_road_14.jpg");
}

#fees-page-header {
  background-image: url("/pub-images/Artillery_road_06.jpg");
}

#FAQ-page-header {
  background-image: url("/pub-images/Artillery_road_04.jpg");
}

#privacy-page-header {
  background-image: url("/pub-images/Artillery_road_20.jpg");
}


strong {
  font-weight: 600 !important;
}


.pink-block {
  background-color: #e6b4bf;
  border-radius: 10px;
  padding: 1em;
}

.pink-block p {
max-width: 90%;
margin-left: auto;
margin-right: auto;
position: relative;
color: #231f20;
padding-top: 1em;
}

.clear-block {
  border-radius: 10px;
  padding: 1em;
}

.clear-block p {
max-width: 90%;
margin-left: auto;
margin-right: auto;
position: relative;
color: #231f20;
padding-top: 1em;
}

.white-block {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1em;
}

#product-block {
    width: 80%;
}

.white-block h5  {
max-width: 90%;
margin-left: auto;
margin-right: auto;
position: relative;
padding-top: 1em;
}

.white-block p {
max-width: 90%;
margin-left: auto;
margin-right: auto;
position: relative;
padding-top: 1em;
}

#features h1 {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

#features  h1  {
  text-decoration: underline;
}

.grey-bg {
  background-color: #231f20;
  padding-top: 2em;
  padding-bottom: 2em;
}

.feature-icon {
  max-width: 90%;
  margin-left: 0.5em;
  position: relative;
  color: #ffffff;
  font-size: 3em;
}

.LykkeLarge {
  max-width: 90%;
  height: auto;
  margin: auto;
}

.card {
  border-radius: 5px;
}

.list-group-item, .card-header {
  color: #231f20;
}

.list-group-item {
  padding: 0.5rem 0.5rem;
    font-size: 1rem;
}

.card-header {
  font-size: 1.1em;
  text-decoration: underline;
  font-weight: 400;
}

@media (max-width: 1024px) {
  #features {
    text-align: center;
}
}

.static-image-bg {
  background-image: url("/pub-images/Artillery_road_23.jpg");
  height: 700px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
  position: relative
}

/* Image Gallery */
.image-gallery {
    background-color: #231f20E6;
}


.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100% !important;
}


/*Contact Form */
.form-control {
    border-width: 2px;
    background-color: #e6b4bf80;
    border-radius: 3px;
    border-color: #231f20;
}

.form-group {
  font-weight: 400;
  font-size: 1em;
}

/* elements */
.background-teal {
  background-color: #0a6e82;
}

.teal-block {
  background-color: #0a6e82;
  border-radius: 10px;
  padding: 1em;
}

.teal-block h5  {
max-width: 90%;
margin-left: auto;
margin-right: auto;
position: relative;
padding-top: 1em;
}

.teal-block p {
max-width: 90%;
margin-left: auto;
margin-right: auto;
position: relative;
padding-top: 1em;
}

.equalize {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.recaptcha div {
  margin-top: 2em;
  text-align: center;
margin-left: auto;
margin-right: auto;
margin-bottom: 2em;
}

.min-div {
  min-height: 60vh !important;
}